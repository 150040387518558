<script lang="ts">
  import LL from "$i18n/i18n-svelte";
  import { MapPin } from "@steeze-ui/heroicons";
  import { Public, PublicOff } from "@steeze-ui/material-design-icons";
  import { Icon } from "@steeze-ui/svelte-icon";
  import { truncateString } from "$src/lib/utils/truncateString";
  import { Avatar, Popover, Tooltip } from "flowbite-svelte";

  export let group;
</script>

<div class="flex flex-col h-full bg-white border rounded-lg shadow-md dark:border-gray-700 dark:bg-gray-800">
  <div class="relative flex w-full">
    <a href={`/groups/${group.shortId}/details`} class="w-full h-full items-center">
      {#if group.headerImageUrl}
        <img class="object-cover w-full rounded-t-lg h-56" src={group.headerImageUrl} alt="" />
      {:else}
        <div class="flex rounded-t-lg h-56 justify-center items-center bg-gray-200 dark:bg-gray-700">
          <svg fill="currentColor" class="w-20 h-20 text-gray-400 dark:text-gray-500" viewBox="0 0 183.11633 147.33672">
            <path
              d="m 178.18207,106.14534 -32.51172,-21.003903 22.10156,-21.04688 c 14.6211,-13.94531 16.32813,-36.0039 3.75,-49.21094 -12.55468,-13.1640601 -34.67968,-12.5546801 -49.32422,1.39454 l -74.031245,70.55078 c -4.042969,3.83984 -4.203125,10.24218 -0.339844,14.285153 3.84375,4.04297 10.242188,4.19922 14.289063,0.33594 L 136.14691,30.922687 c 6.44532,-6.15625 15.94532,-7.1211 20.75,-2.08985 4.78516,5.03125 3.3711,14.48828 -3.07422,20.61719 l -25.60546,24.41797 c -7.65625,7.29687 -6.48438,19.83984 2.3789,25.61328 0.0156,0.008 0.0234,0.0156 0.0234,0.0156 l 37.01562,23.894533 c 1.6875,1.10156 3.59375,1.62109 5.46094,1.62109 3.19141,0 6.31641,-1.51172 8.27344,-4.27734 3.33984,-4.72657 1.67969,-11.44532 -3.1875,-14.58985"
            />
            <path
              d="m 100.42816,80.270337 c -4.95312,0.33203 -8.69531,4.61719 -8.36328,9.56641 1.28907,19.121093 -12.02343,35.894533 -30.964841,39.023443 -9.769531,1.61328 -19.589844,-0.67188 -27.640625,-6.44532 -8.050782,-5.76953 -13.375,-14.32812 -14.992188,-24.101563 -3.328125,-20.17578 10.375,-39.30078 30.550782,-42.63281 3.921874,-0.64844 7.886718,-0.67188 11.777343,-0.0586 4.925781,0.76953 9.5,-2.58985 10.265625,-7.48829 0.765625,-4.90234 -2.585937,-9.5 -7.488281,-10.26562 -5.78125,-0.90234 -11.664063,-0.875 -17.480469,0.082 C 16.139101,42.899207 -4.2046492,71.285927 0.74066373,101.23905 3.1351948,115.74687 11.037539,128.4539 22.990664,137.0203 c 9.457031,6.77735 20.546875,10.32032 31.953125,10.31641 3.011719,0 6.050781,-0.24609 9.082031,-0.74609 28.12109,-4.64454 47.87891,-29.5625 45.96875,-57.960943 -0.33203,-4.94922 -4.61328,-8.69141 -9.56641,-8.35938"
            />
            <path
              d="m 79.81879,29.430497 c 8.07422,-1.33203 13.53906,-8.95703 12.20312,-17.02734 C 90.68988,4.3289369 83.06488,-1.1320031 74.99457,0.2000269 c -8.074219,1.33203 -13.535156,8.95703 -12.203125,17.0273401 1.332031,8.07422 8.957031,13.53907 17.027345,12.20313"
            />
          </svg>
        </div>
      {/if}
    </a>
    <span
      class="absolute bottom-3 left-2 bg-green-400 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full"
      ><div class="flex items-center">
        <Icon class="h-3 w-3 items-center" src={MapPin} />

        <div class="text-xs ml-1">
          {group.location}
        </div>
      </div></span
    >
    <a href={`/profile/${group.ownerShortId}/rides`} class="absolute top-3 right-2">
      <Avatar
        id="avatar{group.id}"
        size="md"
        class="border rounded-full border-emerald-400"
        src={group.ownerPhotoUrl}
      />
    </a>
    {#if group.groupHeaderUrl}
      <a href={`/groups/${group.groupShortId}/details`}>
        <img
          id="group{group.id}"
          class="absolute w-10 h-10 top-3 left-2 rounded border object-cover border-emerald-400"
          src={group.groupHeaderUrl}
          alt="Default avatar"
        />
      </a>
    {/if}
    {#if group.category}
      <span class="absolute bottom-3 right-2 bg-green-400 text-gray-800 text-xs font-medium px-2.5 py-0.5 rounded-full"
        ><div class="flex items-center">
          {#if group.privacy === "private"}
            <Icon class="h-3 w-3 items-center" src={PublicOff} />
          {:else}
            <Icon class="h-3 w-3 items-center" src={Public} />
          {/if}
          <div class="text-xs ml-1">{$LL.createGroup.privacy[group.privacy]()}</div>
        </div></span
      >
    {/if}
  </div>
  <div class="relative p-2 leading-normal w-full h-full">
    <div class="flex items-center justify-between">
      <div>
        <a href={`/groups/${group.shortId}/details`} class="mb-2 text-sm font-bold text-gray-900 dark:text-white">
          {group.name}
        </a>
      </div>
      <div>
        {#if group.type}
          <span
            class="bg-green-100 text-green-800 mr-1 text-xs font-medium px-2 py-0.5 rounded dark:bg-green-900 dark:text-green-300"
            >{$LL.createGroup.type[group.type]()}</span
          >
        {/if}
      </div>
    </div>
    <hr class="h-px m-2 bg-gray-200 border-0 dark:bg-gray-700" />
    <div class="w-full pb-12">
      <p class="p-2 text-sm text-gray-700 dark:text-gray-400">
        {truncateString(group.summary, 250)}
      </p>
    </div>
    <div class="absolute inset-x-0 bottom-0 p-2">
      <hr class="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700" />
      <div class="flex justify-between h-4">
        <div class="flex items-center ml-2">
          <div class="flex w-full">
            <div class="flex w-full items-center">
              <a class="flex w-full" href={`/groups/${group.shortId}/cyclists`}>
                <div class="my-1 text-xs font-normal text-gray-500 dark:text-gray-300">
                  {group.attending || 0} members
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="flex items-center">
          <div class="flex w-full">
            <div class="flex w-full items-center">
              <div class="my-1 text-xs font-normal text-gray-500 dark:text-gray-300">
                {$LL.createGroup.category[group.category]()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<Popover
  arrow={false}
  class="flex justify-center w-48 text-xs rounded-full font-light shadow-none z-10"
  placement="left"
  triggeredBy="#avatar{group.id}"
>
  <a href={`/profile/${group.ownerShortId}/rides`}>{$LL.groupDetails.ownedBy()} {group.ownerName}</a>
</Popover>
{#if group.groupHeaderUrl}
  <Popover
    arrow={false}
    class="text-xs rounded-full font-light shadow-none"
    placement="bottom"
    triggeredBy="#group{group.id}"
  >
    <a href={`/groups/${group.groupShortId}/details`}>{group.groupName}</a>
  </Popover>
{/if}
